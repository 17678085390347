<template>
  <div>
    <eden-table-actions :title="title" @search="setQuery">
      <template slot="actions">
        <riders-export :data="riders" />
      </template>
    </eden-table-actions>
    <el-table :data="setRiders">
      <el-table-column width="200">
        <template slot="header">
          <div class="table--header">
            <span>Name</span>
          </div>
        </template>
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'logistics.directory.rider',
              params: { id: scope.row.id },
            }"
          >
            <span class="font-sm text-primary">
              {{ formatFullName(scope.row) }}</span
            ></router-link
          >
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot="header">
          <div class="table--header">
            <span>Status</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status" :type="setType(scope.row.status)">
            {{ formatText(scope.row.status) }}
          </el-tag>
          <span v-else class="font-sm">-</span>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Phone Number</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">
            {{ formatPhoneNumber(scope.row.phone_number) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Service</span>
          </div>
        </template>
        <template slot-scope="scope">
          <eden-services-list
            v-if="scope.row.services.length"
            :services="scope.row.services"
          />
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot="header">
          <div class="table--header">
            <span>Location Area</span>
          </div>
        </template>
        <template slot-scope="scope">
          <rider-location-areas :location-areas="scope.row.location_areas" />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <div class="table--header">
            <span>Added on</span>
          </div>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{
            formatDate(scope.row.created_at, "do m, y")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="allowAccessFor(['admin', 'operations'])"
        width="80"
      >
        <template slot-scope="scope">
          <rider-actions :rider.sync="scope.row" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "CompanyRiders",
  components: {
    RiderLocationAreas: () =>
      import(
        "@/components/Logistics/Directory/Riders/Rider/RiderLocationAreas"
      ),
    RidersExport: () =>
      import("@/components/Logistics/Directory/Riders/RidersExport"),
    RiderActions: () =>
      import(
        "@/components/Logistics/Directory/Riders/Rider/Actions/RiderActions"
      ),
  },
  props: {
    riders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      query: "",
    };
  },

  computed: {
    title() {
      return `${this.riders.length} Riders`;
    },
    setRiders() {
      const query = this.query.toLowerCase();

      if (query) {
        return this.riders.filter((rider) => {
          return (
            rider.firstname.toLowerCase().includes(query) ||
            rider.lastname.toLowerCase().includes(query)
          );
        });
      } else {
        return this.riders;
      }
    },
  },

  methods: {
    setQuery(query) {
      this.query = query;
    },
  },
};
</script>
